/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { createContext } from "react";
import { AppConfigurationClient  } from "@azure/app-configuration";

const connectionString = process.env.REACT_APP_API_APP_CONFIG_CONN_STRING || "";

const client = new AppConfigurationClient(connectionString);

const SettingsContext = createContext<any>({
    registrationKey: '',
    publishableKey: '',
    priceId: '',
    priceId2: ''
});

async function getSettingValue(configKey = '') {
    const setting = await client.getConfigurationSetting({ key: configKey.toString().trim() });
    return setting.value;
}

export const SettingsContextProvider = (props: any) => {

    const registrationKeyValue = getSettingValue('Stickers:User:RegistrationKey');
    const publishableKeyValue = getSettingValue('Stickers:Stripe:StripePublishableKey');
    const priceIdValue = getSettingValue('Stickers:Stripe:USD2StickerpriceId');
    const priceIdValuePlanB = getSettingValue('Stickers:Stripe:USD2StickerpriceId2');

    const contextValue = {
        registrationKey: registrationKeyValue,
        publishableKey: publishableKeyValue,
        priceId: priceIdValue,
        priceIdPlanB: priceIdValuePlanB
        
    };

    return (
        <SettingsContext.Provider value={contextValue}>
            {props.children}
        </SettingsContext.Provider>
    );
}

export default SettingsContext;
