import React from 'react';
import { useContext } from 'react';
import DashboardAdmin from '../components/Dashboard/DashboardAdmin';
import DashboardCustomer from '../components/Dashboard/DashboardCustomer';
import AuthContext from '../store/auth-context';

const DashboardPage = ():JSX.Element => {
  const authCtx = useContext(AuthContext);

  if (authCtx.role === 'administrator') {
    return <DashboardAdmin />;
  } else {
    return <DashboardCustomer />;
  }

};

export default DashboardPage;
