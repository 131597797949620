import { FC } from 'react';
import { User } from '../../types/Users';
import _ from 'lodash';

import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';

import { StyledTableCell, StyledTableRow, TealSwitch } from './../../utils/TableStyle';

const useStyles = makeStyles({
  table: {
    minWidth: 700
  }
});

type Props = {
  users: User[];
  isLoading: boolean;
  handleChangeUpdateUser: (event: React.ChangeEvent, user: User) => void;
};

const CustomersList: FC<Props> = (props: Props) => {
  const sortedUsers = _.sortBy(props.users, ['userRoles', 'username']);

  const classes = useStyles();

  return (
    <div style={{ width: '100%' }}>
      <h1>Users</h1>
      {props.isLoading && <p>Loading...</p>}
      {!props.isLoading && (
        <TableContainer style={{ width: 700, margin: 'auto' }} component={Paper}>
          <Table className={classes.table} aria-label="customers table">
            <TableHead>
              <TableRow>
                <StyledTableCell align="left">Customer</StyledTableCell>
                <StyledTableCell align="left">Email</StyledTableCell>
                <StyledTableCell align="left">Role</StyledTableCell>
                <StyledTableCell align="left">Is Locked</StyledTableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {sortedUsers.map((user: User, index: number) => (
                <StyledTableRow key={user.id}>
                  <StyledTableCell align="left">{user.customer}</StyledTableCell>
                  <StyledTableCell align="left">{user.email}</StyledTableCell>
                  <StyledTableCell align="left">{user.userRoles}</StyledTableCell>
                  <StyledTableCell align="right">
                    {
                      <TealSwitch
                        checked={user.lockoutEnabled}
                        key={index.toString() + user.lockoutEnabled.toString()}
                        onChange={event => props.handleChangeUpdateUser(event, user)}
                      />
                    }
                  </StyledTableCell>
                </StyledTableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      )}
    </div>
  );
};

export default CustomersList;
