import { FC } from 'react';
import { Sticker } from '../../types/Stickers';

import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';

import { StyledTableCell, StyledTableRow } from './../../utils/TableStyle';

const useStyles = makeStyles({
  table: {
    minWidth: 700
  }
});

type Props = {
  stickers: Sticker[];
  isLoading: boolean;
};

const StickersList: FC<Props> = (props: Props) => {
  const classes = useStyles();

  const thereAreStickers = props.stickers.length > 0;

  return (
    <div>
      <h1>Stickers</h1>
      {props.isLoading && <p>Loading...</p>}
      {!props.isLoading && !thereAreStickers && <p>Not stickers yet :(</p>}
      {!props.isLoading && thereAreStickers && (
        <TableContainer style={{ width: 900, margin: 'auto' }} component={Paper}>
          <Table className={classes.table} aria-label="stickers table">
            <TableHead>
              <TableRow>
                <StyledTableCell align="left">Sticker ID</StyledTableCell>
                <StyledTableCell align="left">ImageUrl</StyledTableCell>
                <StyledTableCell align="left">Size</StyledTableCell>
                <StyledTableCell align="left">Shape</StyledTableCell>
                <StyledTableCell align="left">Created</StyledTableCell>
                <StyledTableCell align="left">Updated</StyledTableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {props.stickers.map((sticker: Sticker) => (
                <StyledTableRow key={sticker.id}>
                  <StyledTableCell align="left">{sticker.id}</StyledTableCell>
                  <StyledTableCell align="left">{sticker.imageUrl}</StyledTableCell>
                  <StyledTableCell align="left">{sticker.size}</StyledTableCell>
                  <StyledTableCell align="left">{sticker.shape}</StyledTableCell>
                  <StyledTableCell align="left">
                    {sticker.created &&
                      new Intl.DateTimeFormat('en-US', {
                        year: 'numeric',
                        month: '2-digit',
                        day: '2-digit'
                      }).format(new Date(sticker.created.toString()))}
                  </StyledTableCell>
                  <StyledTableCell align="left">
                    {sticker.updated &&
                      new Intl.DateTimeFormat('en-US', {
                        year: 'numeric',
                        month: '2-digit',
                        day: '2-digit'
                      }).format(new Date(sticker.updated.toString()))}
                  </StyledTableCell>
                </StyledTableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      )}
    </div>
  );
};

export default StickersList;
