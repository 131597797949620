import { FC } from 'react';
import { Order } from '../../types/Orders';
import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import Link from '@material-ui/core/Link';

import { StyledTableCell, StyledTableRow } from './../../utils/TableStyle';

const useStyles = makeStyles({
  table: {
    minWidth: 500
  }
});

type Props = {
  invoices: Order[];
  isLoading: boolean;
};

const InvoicesList: FC<Props> = (props: Props) => {
  const classes = useStyles();

  const thereAreInvoices = props.invoices.length > 0;

  return (

    <div style={{ width: '100%' }}>
      <h2>Invoices</h2>
      {props.isLoading && <p>Loading...</p>}
      {!props.isLoading && !thereAreInvoices && <p>Not Invoices yet :(</p>}
      {!props.isLoading && thereAreInvoices && (
        <div style={{ width: '100%' }}>
          <TableContainer style={{ width: '50%', margin: 'auto' }} component={Paper}>
            <Table className={classes.table} aria-label="Invoices table">
              <TableHead>
                <TableRow>
                  <StyledTableCell align="center">Invoice Number</StyledTableCell>
                  <StyledTableCell align="center">Pdf Invoice</StyledTableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {props.invoices.map((invoice: Order, index: number) => (
                  <StyledTableRow key={index}>
                      <StyledTableCell align="center">
                    {invoice.invoiceId}
                    </StyledTableCell>
                    <StyledTableCell align="center">
                      <Link href={invoice.invoicePdf} underline="hover">
                        Download invoice
                      </Link>
                    </StyledTableCell>
                  </StyledTableRow>
                ))}
                <TableRow>
                  <TableCell></TableCell>
                  <TableCell></TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </TableContainer>
        </div>
      )}
    </div>
  );
};

export default InvoicesList;
