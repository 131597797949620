import { useEffect, useContext, useState, FC } from 'react';
import classes from './Dashboard.module.css';
import OrdersList from '../Orders/OrdersList';
import AuthContext from '../../store/auth-context';
import StickersList from '../Stickers/StickersList';
import { User } from '../../types/Users';
import { Order } from '../../types/Orders';
import { Sticker } from '../../types/Stickers';
import CustomerList from '../Customer/CustomerList';
import { Box, makeStyles } from '@material-ui/core';
import { useHistory } from 'react-router';
import Button from '@material-ui/core/Button';

const useStyles = makeStyles(() => ({
  button: {
    backgroundColor: 'teal'
  },
  itemRight: {
    color: 'white',
    backgroundColor: 'teal',
    top: '50%',
    right: '15%',
    float: 'right',
    position: 'relative',
    transform: 'translateY(-50%)'
  }
}));

const DashboardAdmin: FC = () => {
  const history = useHistory();
  const materialClasses = useStyles();

  const [orders, setOrders] = useState<Order[]>([]);
  const [ordersCurrentMonth, setOrdersCurrentMonth] = useState<Order[]>([]);
  const [stickers, setStickers] = useState<Sticker[]>([]);
  const [users, setUsers] = useState<User[]>([]);
  const [isLoadingOrders, setIsLoadingOrders] = useState(false);
  const [isLoadingStickers, setIsLoadingStickers] = useState(false);
  const [isLoadingUsers, setIsLoadingUsers] = useState(false);

  const authCtx = useContext(AuthContext);
  const urlOrders = process.env.REACT_APP_API_GATEWAY + 'getAllOrders';
  const urlStickers = process.env.REACT_APP_API_GATEWAY + 'getAllStickers';
  const urlUsers = process.env.REACT_APP_API_GATEWAY + 'getAllUsers';
  const authHeaderValue = 'Bearer ' + authCtx.token;

  const currentYear = new Date().getFullYear();
  const currentMonth = new Date().getMonth() + 1;

  const handleLockoutUser = (event: React.ChangeEvent, user: User) => {
    const userId = user.id;
    const lockEnabled = !user.lockoutEnabled;

    const changedUserIndex = users.findIndex(user => user.id === userId);
    users[changedUserIndex].lockoutEnabled = lockEnabled;
    setUsers(users);

    const urlUpdateUser =
      process.env.REACT_APP_API_MANAGEMENT_LOCKOUT_USER +
      '?userId=' +
      userId +
      '&lockEnabled=' +
      lockEnabled;

    setIsLoadingUsers(true);

    fetch(urlUpdateUser, {
      method: 'PUT'
    }).then(async res => {
      setIsLoadingUsers(false);
      if (!res.ok) {
        const errorMessage = 'update failed';
        throw new Error(errorMessage);
      }
    });
  };

  useEffect(() => {
    setIsLoadingOrders(true);
    async function getAllOrders() {
      const response = await fetch(urlOrders, {
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          Authorization: authHeaderValue
        }
      });
      const data = await response.json();
      setOrders(data);
      const currentMonthOrders = data.filter((orderItem: Order) => {
        const [year, month] = orderItem.created.toString().split('-');
        if (currentMonth === parseInt(month) && currentYear === parseInt(year)) {
          return orderItem;
        } else {
          return null;
        }
      });
      setOrdersCurrentMonth(currentMonthOrders);
      setIsLoadingOrders(false);
    }
    getAllOrders();
  }, [authCtx.token, authHeaderValue, currentMonth, currentYear, urlOrders]);

  useEffect(() => {
    setIsLoadingStickers(true);
    async function getAllStickers() {
      const response = await fetch(urlStickers, {
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          Authorization: authHeaderValue
        }
      });
      const data = await response.json();
      setStickers(data);
      setIsLoadingStickers(false);
    }
    getAllStickers();
  }, [authHeaderValue, urlStickers]);

  useEffect(() => {
    setIsLoadingUsers(true);
    async function getAllUsers() {
      const response = await fetch(urlUsers, {
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          Authorization: authHeaderValue
        }
      });
      const data = await response.json();
      setUsers(data);
      setIsLoadingUsers(false);
    }
    getAllUsers();
  }, [authHeaderValue, urlUsers]);

  return (
    <div className={classes.dashboard}>
      <section>
        <h1>Stickers as a Service - Users Dashboard</h1>
        <Box className={classes.titleBar}>
          <form className={materialClasses.itemRight}>
            <Button
              variant="contained"
              color="primary"
              className={materialClasses.button}
              disableElevation
              onClick={() => history.push('/createUser')}
            >
              Create User
            </Button>
          </form>
        </Box>
        <br />
      </section>
      <section>
        <h1>List of Users (admins and customers)</h1>
        <CustomerList
          users={users}
          isLoading={isLoadingUsers}
          handleChangeUpdateUser={(event: React.ChangeEvent, user: User) =>
            handleLockoutUser(event, user)
          }
        />
      </section>
      <section>
        <h1>Stickers as a Service - Orders Dashboard</h1>
        <OrdersList orders={orders} isLoading={isLoadingOrders} />
      </section>
      <section>
        <h1>Stickers as a Service - Current Month Orders</h1>
        <OrdersList orders={ordersCurrentMonth} isLoading={isLoadingOrders} />
      </section>
      <section>
        <h1>Stickers as a Service - Stickers Dashboard</h1>
        <StickersList stickers={stickers} isLoading={isLoadingStickers} />
      </section>
    </div>
  );
};

export default DashboardAdmin;
