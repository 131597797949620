import React from "react";

const CheckoutSuccess = (): JSX.Element => {
    return (
        <div>
            <h1>Checkout Success</h1>
        </div>
    );
};

export default CheckoutSuccess;
