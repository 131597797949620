import React from "react";
const CheckoutCancel = (): JSX.Element => {
    return (
        <div>
            <h1>Checkout Cancel</h1>
        </div>
    );
};

export default CheckoutCancel;
