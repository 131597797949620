import React from 'react';
import { useContext } from 'react'
import { Link } from 'react-router-dom'
import AuthContext from '../../store/auth-context'
import classes from './MainNavigation.module.css'

const MainNavigation = (): JSX.Element => {
  const authCtx = useContext(AuthContext)

  const isLoggedIn = authCtx.isLoggedIn

  const logoutHandler = () => {
    authCtx.logout()
  }

  return (
    <header className={classes.header}>
      <Link to="/">
        <div className={classes.logo}>Stickers as a Service</div>
      </Link>
      <nav>
        <ul>
          {isLoggedIn && (
            <li>
              <Link to="/profile">Profile</Link>
            </li>
          )}
          {isLoggedIn && (
            <li>
              <Link to="/documentation">Documentation</Link>
            </li>
          )}
          {isLoggedIn && (
            <li>
              <Link to="/dashboard">Dashboard</Link>
            </li>
          )}
          {isLoggedIn && authCtx.role !== 'administrator' && (
            <li>
              <Link to="/checkout">Subscribe</Link>
            </li>
          )}
          {isLoggedIn && (
            <li>
              <Link to="/stickers">Add Stickers</Link>
            </li>
          )}
          {!isLoggedIn && (
            <li>
              <Link to="/auth">Login</Link>
            </li>
          )}
          {isLoggedIn && (
            <li>
              <button onClick={logoutHandler}>Logout</button>
            </li>
          )}
        </ul>
      </nav>
    </header>
  )
}

export default MainNavigation
