import React, { FC, Fragment } from 'react';

import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import TextField from '@material-ui/core/TextField';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import Link from '@material-ui/core/Link';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import { TextFieldProps } from 'material-ui';
import { Copyright } from '../../utils/FormStyle';
import { useState, useRef, useContext } from 'react';
import { useHistory } from 'react-router-dom';
import AuthContext from '../../store/auth-context';
import AlertMessage from '../Alerts/AlertMessage';

const useStyles = makeStyles(theme => ({
  paper: {
    marginTop: theme.spacing(8),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center'
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: 'teal'
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(1)
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
    backgroundColor: 'teal'
  }
}));

let passwordChgTries = 1;

const AuthForm: FC = () => {
  const classes = useStyles();

  const history = useHistory();

  const emailInputRef = useRef<TextFieldProps>(null);
  const passwordInputRef = useRef<TextFieldProps>(null);

  const authCtx = useContext(AuthContext);

  const [isLoading, setIsLoading] = useState(false);
  const [alertType, setAlertType] = useState<number>(200);
  const [alertMessage, setAlertMessage] = useState<string>('');

  const submitHandler = (event: React.FormEvent) => {
    event.preventDefault();

    const enteredEmail = emailInputRef.current?.value;
    const enteredPassword = passwordInputRef.current?.value;

    setIsLoading(true);

    const url = process.env.REACT_APP_API_MANAGEMENT_AUTHENTICATE_USER as string;

    fetch(url, {
      method: 'POST',
      body: JSON.stringify({
        username: enteredEmail,
        password: enteredPassword
      }),
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json'
      }
    })
      .then(res => {
        setIsLoading(false);
        if (res.ok) {
          return res.json();
        } else {
          return res.json().then(data => {
            let errorMessage = 'Login failed';
            if (data) {
              errorMessage = data;
            }
            throw new Error(errorMessage);
          });
        }
      })
      .then(data => {
        authCtx.login(data.token);
        history.replace('/');
      })
      .catch(err => {
        setAlertMessage(err.message + `. Wrong user or password. Try ${passwordChgTries}`);
        setAlertType(401);
        passwordChgTries += 1;
      });
  };

  return (
    <Fragment>
      <AlertMessage message={alertMessage} type={alertType} key={alertMessage}/>
      <Container component="main" maxWidth="xs">
        <CssBaseline />
        <div className={classes.paper}>
          <Avatar className={classes.avatar}>
            <LockOutlinedIcon />
          </Avatar>
          <Typography component="h1" variant="h5">
            Sign in
          </Typography>
          <form className={classes.form} noValidate onSubmit={submitHandler}>
            <TextField
              variant="outlined"
              margin="normal"
              required
              fullWidth
              id="email"
              label="Email Address"
              name="email"
              autoComplete="email"
              autoFocus
              inputRef={emailInputRef}
            />
            <TextField
              variant="outlined"
              margin="normal"
              required
              fullWidth
              name="password"
              label="Password"
              type="password"
              id="password"
              autoComplete="current-password"
              inputRef={passwordInputRef}
            />
            <FormControlLabel
              control={<Checkbox value="remember" color="primary" />}
              label="Remember me"
            />
            <Button
              type="submit"
              fullWidth
              variant="contained"
              className={classes.submit}
              disabled={isLoading}
              color="primary"
            >
              {isLoading !== true ? 'Sign In' : 'Authenticating'}
            </Button>
            <Grid container>
              <Grid item xs></Grid>
              <Grid item>
                <Link href={window.location.origin + '/forgotPassword'} variant="body2">
                  Forgot password?
                </Link>
              </Grid>
            </Grid>
          </form>
        </div>
        <Box mt={8}>
          <Copyright />
        </Box>
      </Container>
    </Fragment>
  );
};

export default AuthForm;
