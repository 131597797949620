import { FC } from 'react';
import { Order } from '../../types/Orders';

import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';

import { StyledTableCell, StyledTableRow } from './../../utils/TableStyle'

const useStyles = makeStyles({
  table: {
    minWidth: 700
  }
})

type Props = {
  orders: Order[]
  isLoading: boolean
  nextPayDate?: Date | null
}

const OrdersList: FC<Props> = (props: Props) => {
  function totalFromOrders(monthOrders: Order[]) {
    let sum = 0
    Object.values(monthOrders).forEach(mOrder => (sum += mOrder.orderTotal))
    return sum
  }
  const classes = useStyles()

  const totalAmount = totalFromOrders(props.orders)
  const thereAreOrders = props.orders.length > 0

  return (
    <div style={{ width: '100%' }}>
      <h2>Orders</h2>
      {props.isLoading && <p>Loading...</p>}
      {!props.isLoading && !thereAreOrders && <p>No orders yet :(</p>}
      {!props.isLoading && props.nextPayDate && (
        <p>
          <h3>
            Your next payment date is on &nbsp;
            {new Intl.DateTimeFormat('en-us', {
              year: 'numeric',
              month: '2-digit',
              day: '2-digit'
            }).format(new Date(props.nextPayDate.toString()))}
          </h3>
        </p>
      )}
      {!props.isLoading && thereAreOrders && (
        <div style={{ width: '100%' }}>
          <TableContainer style={{ width: '75%', margin: 'auto' }} component={Paper}>
            <Table className={classes.table} aria-label="orders table">
              <TableHead>
                <TableRow>
                  <StyledTableCell align="left">Recipient</StyledTableCell>
                  <StyledTableCell align="left">Created</StyledTableCell>
                  <StyledTableCell align="left">Shipped</StyledTableCell>
                  <StyledTableCell align="left">Address</StyledTableCell>
                  <StyledTableCell align="left">Status</StyledTableCell>
                  <StyledTableCell align="center">Stickers</StyledTableCell>
                  <StyledTableCell align="right">Total</StyledTableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {props.orders.map((order: Order, index: number) => (
                  <StyledTableRow key={index}>
                    <StyledTableCell align="left">{order.recipientFullName}</StyledTableCell>
                    <StyledTableCell align="left">
                      {order.created &&
                        new Intl.DateTimeFormat('en-US', {
                          year: 'numeric',
                          month: '2-digit',
                          day: '2-digit'
                        }).format(new Date(order.created.toString()))}
                    </StyledTableCell>
                    <StyledTableCell align="left">
                      {order.shippingDate &&
                        new Intl.DateTimeFormat('en-US', {
                          year: 'numeric',
                          month: '2-digit',
                          day: '2-digit'
                        }).format(new Date(order.shippingDate.toString()))}
                    </StyledTableCell>
                    <StyledTableCell align="left">{order.address1}</StyledTableCell>
                    <StyledTableCell align="left">{order.status}</StyledTableCell>
                    <StyledTableCell>
                      <Table className={classes.table} aria-label="orders stickers table">
                        <TableHead>
                          <TableRow>
                            <StyledTableCell align="left">ImageUrl</StyledTableCell>
                            <StyledTableCell align="left">Shape</StyledTableCell>
                            <StyledTableCell align="left">Size</StyledTableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {order.stickers.map((sticker, index: number) => (
                            <StyledTableRow key={index}>
                              <StyledTableCell>{sticker.imageUrl}</StyledTableCell>
                              <StyledTableCell>{sticker.shape}</StyledTableCell>
                              <StyledTableCell>{sticker.size}</StyledTableCell>
                            </StyledTableRow>
                          ))}
                        </TableBody>
                      </Table>
                    </StyledTableCell>
                    <StyledTableCell>
                      {order.orderTotal &&
                        new Intl.NumberFormat('en-us', {
                          style: 'currency',
                          currency: 'USD'
                        }).format(order.orderTotal)}
                    </StyledTableCell>
                  </StyledTableRow>
                ))}
                <TableRow>
                  <TableCell></TableCell>`<TableCell></TableCell>
                  <TableCell></TableCell>
                  <TableCell></TableCell>
                  <TableCell></TableCell>
                  <TableCell>Total</TableCell>`
                  <TableCell>
                    {totalAmount &&
                      new Intl.NumberFormat('en-us', {
                        style: 'currency',
                        currency: 'USD'
                      }).format(totalAmount)}
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </TableContainer>
        </div>
      )}
    </div>
  )
}

export default OrdersList
