import React, { useState, useRef, FC, Fragment } from 'react';

import { CssBaseline, FormControl, makeStyles } from '@material-ui/core';
import Typography from '@material-ui/core/Typography';
import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Container from '@material-ui/core/Container';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import AlertMessage from '../Alerts/AlertMessage';

const useStyles = makeStyles(theme => ({
  paper: {
    marginTop: theme.spacing(8),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center'
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: 'teal'
  },
  form: {
    width: '100%',
    marginTop: theme.spacing(1)
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
    backgroundColor: 'teal'
  },
  formControl: {
    margin: theme.spacing(0),
    minWidth: 120
  },
  selectEmpty: {
    marginTop: theme.spacing(2)
  },
  container: { margin: theme.spacing(2) },
  textArea: { fontSize: '1.2em' },
  boldText: { fontWeight: 'bold' },
  cursiveText: { fontStyle: 'italic' }
}));

const ForgotPasswordForm: FC = () => {
  const classes = useStyles();

  const [alertType, setAlertType] = useState<number>(200);
  const [alertMessage, setAlertMessage] = useState<string>('');

  const emailInputRef = useRef<HTMLInputElement | null>(null);
  const [isLoading, setIsLoading] = useState(false);

  const submitHandler = (event: React.FormEvent) => {
    event.preventDefault();

    const enteredEmail = emailInputRef.current?.value;

    setIsLoading(true);

    const url = process.env.REACT_APP_API_MANAGEMENT_RESET_PASSWORD_USER as string;
    fetch(url, {
      method: 'POST',
      body: JSON.stringify({
        email: enteredEmail
      }),
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json'
      }
    })
      .then(async res => {
        setIsLoading(false);
        if (res.ok) {
          return res.json();
        } else {
          const data = await res.json();
          let errorMessage = 'Password reset failed';
          if (data) {
            errorMessage = data;
          }
          throw new Error(errorMessage);
        }
      })
      .then(() => {
        setAlertMessage('Password reset with success, go login and use received password.');
        setAlertType(200);
      })
      .catch(err => {
        setAlertMessage(err.message);
        setAlertType(500);
      });
  };

  return (
    <Fragment>
      <AlertMessage message={alertMessage} type={alertType} />
      <Container component="main" maxWidth="xs">
        <CssBaseline />
        <div className={classes.paper}>
          <Avatar className={classes.avatar}>
            <LockOutlinedIcon />
          </Avatar>
          <Typography className={classes.textArea}>
            <p>
              Enter your email address and click on the button to reset your password. You&apos;ll
              receive an email with information about your new password.
            </p>
            <p>
              In case you don&apos;t see an email at your inbox please check your junk email folder, look for
              an email from <span className={classes.boldText}>stickersaas@outlook.com</span>, with
              the subject:
            </p>
            <p className={classes.cursiveText}>&quot;Stickers as a service password reset&quot;</p>
          </Typography>
          <form className={classes.form} onSubmit={submitHandler}>
            <FormControl required className={classes.formControl} fullWidth>
              <TextField
                variant="outlined"
                required
                fullWidth
                id="email"
                label="Your Email"
                name="email"
                autoComplete="email"
                margin="normal"
                autoFocus
                inputRef={emailInputRef}
              />
            </FormControl>
            <Button
              type="submit"
              fullWidth
              variant="contained"
              color="primary"
              disabled={isLoading}
              className={classes.submit}
            >
              {isLoading !== true ? 'Reset Password' : 'Resetting'}
            </Button>
          </form>
        </div>
      </Container>
    </Fragment>
  );
};

export default ForgotPasswordForm;
