import { withStyles } from '@material-ui/core/styles';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import { Switch } from '@material-ui/core';
import grey from '@material-ui/core/colors/grey';
import teal from '@material-ui/core/colors/teal';

export const StyledTableCell = withStyles(theme => ({
  head: {
    backgroundColor: theme.palette.common.white,
    color: theme.palette.common.black,
    fontWeight: 600
  }
}))(TableCell);

export const StyledTableRow = withStyles(theme => ({
  root: {
    '&:nth-of-type(odd)': {
      backgroundColor: theme.palette.action.hover
    }
  }
}))(TableRow);

export const TealSwitch = withStyles({
  switchBase: {
    color: "#FFFFFF",
    '&$checked': {
      color: teal[300],
    },
    '&$checked + $track': {
      backgroundColor: grey[300],
    },
  },
  checked: {},
  track: {},
})(Switch);
