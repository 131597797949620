import React from 'react';
import classes from './Documentation.module.css';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import { StyledTableCell, StyledTableRow } from './../../utils/TableStyle';
import Link from '@material-ui/core/Link';
import Card from '@material-ui/core/Card/Card';
import CardMedia from '@material-ui/core/CardMedia';
import Img_AuthToken from './images/authToken.png';
import Img_SampleEndpoint from './images/sampleEndpoint.gif';

const Documentation = (): JSX.Element => {
  return (
    <div className={classes.container}>
      <h1>Stickers as a Service - Documentation</h1>
      <p>
        Authentication via API Key should be done through{' '}
        <b>https://saasapimanage.azurewebsites.net/api/authentication/authApiKey</b> <br />
        <br />
        For that, add in the header: &apos;<b>apikey</b>&apos; and the value will be your API Key
        already sent to your e-mail. See the example below:
        <br />
        <br />
        <Card style={{ maxWidth: 800 }}>
          <CardMedia component="img" image={Img_AuthToken} alt="Authentication token image" />
        </Card>
        <br />
        Once you are authenticated, use the generated token at the other endpoints.
        <br />
        <br />
        The base url for all the endpoints is <b>https://saasapigateway.azurewebsites.net/</b>
      </p>
      <p>
        All the endpoints (except for &apos;Reset password&apos;) uses an Authorization Bearer
        header.
      </p>

      <p>
        <b>Example:</b> <br />
        Make a request to the endpoint <b>&apos;getAllOrders:&apos;</b> <br />
        <b>Url:</b> https://saasapigateway.azurewebsites.net/api/getAllOrders
        <br />
        <b>Http verb:</b> GET <br />
        <b>Header</b>, key: Authorization, <b>Value</b>, Bearer Token generated by the
        Authentication endpoint.
      </p>
      <Card style={{ maxWidth: 800 }}>
        <CardMedia component="img" image={Img_SampleEndpoint} alt="Sample use of an endpoint" />
      </Card>
      <div className={classes.text}>
        <p>
          <b>Endpoint permissions by roles:</b>
        </p>
      </div>

      <div style={{ width: '100%' }}>
        <TableContainer style={{ width: '70%', margin: 'auto' }} component={Paper}>
          <Table className={classes.table} aria-label="Endpoints table">
            <TableHead>
              <TableRow>
                <StyledTableCell align="left">Administrator</StyledTableCell>
                <StyledTableCell align="left">Customers</StyledTableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              <StyledTableRow>
                <StyledTableCell align="left">/api/addSticker</StyledTableCell>
                <StyledTableCell align="left">/api/addSticker</StyledTableCell>
              </StyledTableRow>
              <StyledTableRow>
                <StyledTableCell align="left">/api/deleteSticker</StyledTableCell>
                <StyledTableCell align="left">/api/getOrdersByUser?userId=</StyledTableCell>
              </StyledTableRow>
              <StyledTableRow>
                <StyledTableCell align="left">/api/updateSticker</StyledTableCell>
                <StyledTableCell align="left">/api/getOrderById?id=</StyledTableCell>
              </StyledTableRow>
              <StyledTableRow>
                <StyledTableCell align="left">/api/updateOrder</StyledTableCell>
                <StyledTableCell align="left">/api/addOrder</StyledTableCell>
              </StyledTableRow>
              <StyledTableRow>
                <StyledTableCell align="left">/api/getAllOrders</StyledTableCell>
                <StyledTableCell align="left">/api/getInvoicesByUser</StyledTableCell>     
              </StyledTableRow>
              <StyledTableRow>
                <StyledTableCell align="left">/api/getAllOpesnOrders</StyledTableCell>
                <StyledTableCell align="left">/api/authentication/resetPassword</StyledTableCell>
              </StyledTableRow>
              <StyledTableRow>
                <StyledTableCell align="left">/api/getAllUsers</StyledTableCell>
                <StyledTableCell align="left">/api/authentication/authApiKey</StyledTableCell>
              </StyledTableRow>
              <StyledTableRow>
                <StyledTableCell align="left"></StyledTableCell>
                <StyledTableCell align="left">/api/authentication/authenticate</StyledTableCell>
              </StyledTableRow>
            </TableBody>
          </Table>
        </TableContainer>
      </div>
      <div className={classes.text}>
        <p>
          <b>List of links for Swagger endpoints</b>
        </p>
      </div>

      <div style={{ width: '100%' }}>
        <TableContainer style={{ width: '70%', margin: 'auto' }} component={Paper}>
          <Table className={classes.table} aria-label="Endpoints table">
            <TableHead>
              <TableRow>
                <StyledTableCell align="left">Stickers</StyledTableCell>
                <StyledTableCell align="left">Orders</StyledTableCell>
                <StyledTableCell align="left">Customers</StyledTableCell>
                <StyledTableCell align="left">Payments</StyledTableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              <StyledTableRow>
                <StyledTableCell align="left">
                  <Link
                    href="https://saasapigateway.azurewebsites.net/api/swagger-stickers"
                    underline="hover"
                  >
                    Stickers Swagger JSON doc endpoint
                  </Link>
                </StyledTableCell>
                <StyledTableCell align="left">
                  <Link
                    href="https://saasapigateway.azurewebsites.net/api/swagger-orders"
                    underline="hover"
                  >
                    Orders Swagger JSON doc endpoint
                  </Link>
                </StyledTableCell>
                <StyledTableCell align="left">
                  <Link
                    href="https://saasapigateway.azurewebsites.net/api/swagger-customers"
                    underline="hover"
                  >
                    Customers Swagger JSON doc endpoint
                  </Link>
                </StyledTableCell>
                <StyledTableCell align="left">
                  <Link
                    href="https://saasapigateway.azurewebsites.net/api/swagger-payments"
                    underline="hover"
                  >
                    Payments Swagger JSON doc endpoint
                  </Link>
                </StyledTableCell>
              </StyledTableRow>
            </TableBody>
          </Table>
        </TableContainer>
      </div>
    </div>
  );
};

export default Documentation;
