import React, { Fragment } from 'react';
import { useState, useRef, useContext } from 'react';
import AuthContext from '../../store/auth-context';
import { CssBaseline, FormControl, makeStyles, MenuItem, Select, InputLabel } from '@material-ui/core';
import Typography from '@material-ui/core/Typography';
import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Container from '@material-ui/core/Container';
import ImageOutlinedIcon from '@material-ui/icons/ImageOutlined';
import AlertMessage from '../Alerts/AlertMessage';

const useStyles = makeStyles(theme => ({
  paper: {
    marginTop: theme.spacing(8),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center'
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: 'teal'
  },
  form: {
    width: '100%',
    marginTop: theme.spacing(1)
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
    backgroundColor: 'teal'
  },
  formControl: {
    margin: theme.spacing(0),
    minWidth: 120
  },
  selectEmpty: {
    marginTop: theme.spacing(2)
  }
}));

const CreateStikerForm = (): JSX.Element => {
const classes = useStyles();
const [alertType, setAlertType] = useState<number>(200);
const [alertMessage, setAlertMessage] = useState<string>('');


  const imageUrlInputRef = useRef<HTMLInputElement | null>(null);
  const sizeInputRef = useRef<HTMLInputElement | null>(null);
  const shapeInputRef = useRef<HTMLSelectElement | null>(null);

  const [isLoading, setIsLoading] = useState(false);
  const authCtx = useContext(AuthContext);
  const authHeaderValue = 'Bearer ' + authCtx.token;
  const submitHandler = (event: React.FormEvent) => {
    event.preventDefault();

    const enteredImageUrl = imageUrlInputRef.current?.value;
    const enteredSize = sizeInputRef.current?.value;
    const enteredShape = shapeInputRef.current?.value;

    setIsLoading(true);

    const url = process.env.REACT_APP_API_GATEWAY + `addSticker`;

      fetch(url, {
        method: 'POST',
        body: JSON.stringify({
        imageUrl: enteredImageUrl,
        size:enteredSize,
        shape: enteredShape
        }),
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          Authorization: authHeaderValue
        }
      }).then(res => {
        setIsLoading(false);
        if (res.ok) {
          setAlertMessage(`Sticker with size ${enteredSize} and shape of type ${enteredShape} created with success.`);
          setAlertType(200);
          return res.json();
        } else {
          return res.json().then(data => {
            let errorMessage = 'Creation failed';
            if (data) {
              errorMessage = data;
            }
            setAlertMessage(errorMessage);
            setAlertType(500);
          });
        }
      });
 
  };

  return (
    <Fragment>
      <AlertMessage message={alertMessage} type={alertType} key={alertMessage} />
      <Container component="main" maxWidth="xs">
        <CssBaseline />
        <div className={classes.paper}>
          <Avatar className={classes.avatar}>
            <ImageOutlinedIcon />
          </Avatar>
          <Typography component="h1" variant="h5">
           Sticker registration
          </Typography>
          <form className={classes.form} noValidate onSubmit={submitHandler}>
            <FormControl required className={classes.formControl} fullWidth>
              <TextField
                variant="outlined"
                required
                fullWidth
                id="sticker"
                label="Sticker URL"
                name="sticker"
                margin="normal"
                autoFocus
                inputRef={imageUrlInputRef}
              />
            </FormControl>
            <FormControl required className={classes.formControl} fullWidth>
            <InputLabel>Select size</InputLabel>
              <Select name="size" required id="size" label="Sticker size" inputRef={sizeInputRef}>
                <MenuItem value="">
                  <em>Select size</em>
                </MenuItem>
                <MenuItem value="1.0">1.0</MenuItem>
                <MenuItem value="1.5">1.5</MenuItem>
                <MenuItem value="2">2</MenuItem>
                <MenuItem value="2.5">2.5</MenuItem>
                <MenuItem value="3">3</MenuItem>
              </Select>
            </FormControl>
            <FormControl required className={classes.formControl} fullWidth>
            <InputLabel>Select shape</InputLabel>
            <Select name="shape" required id="shape" label="Sticker shape" inputRef={shapeInputRef}>
                <MenuItem value="">
                  <em>Select shape</em>
                </MenuItem>
                <MenuItem value="circle">Circle</MenuItem>
                <MenuItem value="semicircle">Semicircle</MenuItem>
                <MenuItem value="square">Square</MenuItem>
                <MenuItem value="triangle">Triangle</MenuItem>
                <MenuItem value="rectangle">Rectangle</MenuItem>
                <MenuItem value="oval">Oval</MenuItem>
                <MenuItem value="heart">Heart</MenuItem>
                <MenuItem value="star">Star</MenuItem>
              </Select>
            </FormControl>
            <div>
              {isLoading && <p>Creating...</p>}
              {!isLoading && (
                <Button
                  type="submit"
                  fullWidth
                  variant="contained"
                  color="primary"
                  className={classes.submit}
                >
                  Register Sticker
                </Button>
              )}
            </div>
          </form>
        </div>
      </Container>
    </Fragment>
  );
};

export default CreateStikerForm;
