import React from "react";
import { useContext, useEffect, useState } from "react";
import Loader from 'react-loader-spinner';
import AuthContext from "../../store/auth-context";
import SettingsContext from "../../store/settings-context";
import classes from './Billing.module.css';
import { loadStripe } from "@stripe/stripe-js";
import { CustomerSubscription } from "../../types/CustomerSubscription";
import { StripeSession } from "../../types/StripeSession";
import { Container, CssBaseline } from "@material-ui/core";

const Checkout = (): JSX.Element => {
    const [spinnerLoading, setSpinnerLoading] = useState(false);
    const [hasSubscription, setHasSubscription] = useState(true);
    const [itIsChecking, setItIsChecking] = useState(true);

    const authCtx = useContext(AuthContext);
    const settingsCtx = useContext(SettingsContext);

    const authHeaderValue = 'Bearer ' + authCtx.token;
    const urlCustomerSubscription = process.env.REACT_APP_API_GATEWAY + `getCustomerSubscriptionByCustomer?userId=${authCtx.userId}`;

    useEffect(() => {
        async function getCustomerSubscription() {
            const response = await fetch(urlCustomerSubscription,
                {
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/json',
                        'Authorization': authHeaderValue
                    },
                }
            );
            await response.json().then((data: CustomerSubscription) => {
                setItIsChecking(false);
                if (data.stripeSubscriptionId) {
                    setHasSubscription(true);
                } else {
                    setHasSubscription(false);
                }
            })
        }
        getCustomerSubscription();
    }, [authHeaderValue, urlCustomerSubscription])

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const createCheckoutSession = (priceId: string):any => {
        setSpinnerLoading(true);

        const url = process.env.REACT_APP_API_GATEWAY + 'createCheckoutSession';

        return fetch(url, {
            method: 'POST',
            headers: {
                "Content-Type": "application/json",
                "Authorization": authHeaderValue,
            },
            body: JSON.stringify({
                priceId: priceId,
                paymentMethodTypes: [
                    "card"
                ],
                successUrl: window.location.origin + '/checkoutSuccess',
                cancelUrl: window.location.origin + '/checkoutCancel',
                lineItems: [
                    { price: priceId }
                ]
            })
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        }).then((result: any) => {
            setSpinnerLoading(false);
            return result.json();
        });
    }

    const handleClickCheckout = (e: React.MouseEvent) => {
        e.preventDefault();

        settingsCtx.publishableKey.then((publishableKey: string) => {
            settingsCtx.priceId.then((priceId: string) => {
                createCheckoutSession(priceId).then((data: StripeSession) => {
                    const stripe = loadStripe(publishableKey);
                    // eslint-disable-next-line @typescript-eslint/no-explicit-any
                    stripe.then((Stripe: any) => {
                        Stripe.redirectToCheckout({
                            sessionId: data.sessionId
                        });
                    });
                });
            });
        });
    };

    const handleClickCheckoutPlanB = (e: React.MouseEvent) => {
        e.preventDefault();

        settingsCtx.publishableKey.then((publishableKey: string) => {
            settingsCtx.priceIdPlanB.then((priceId: string) => {
                createCheckoutSession(priceId).then((data: StripeSession) => {
                    const stripe = loadStripe(publishableKey);
                    // eslint-disable-next-line @typescript-eslint/no-explicit-any
                    stripe.then((Stripe: any) => {
                        Stripe.redirectToCheckout({
                            sessionId: data.sessionId
                        });
                    });
                });
            });
        });
    };

    return ( 
        <React.Fragment>
        <CssBaseline />
        <Container fixed>
        <h1>Subscription Checkout</h1>
<section className={classes.billing}>
<p className={classes.planTitle}>Plan A</p>
<form>
    <div className={classes.control}>
        <p>If you already have a subscription, adding a new one will be disabled</p>
        {itIsChecking && <p>Checking if subscription exists, please wait</p>}
    </div>
    <div className={classes.actions}>
        <Loader
            type="Puff"
            color="#00BFFF"
            height={75}
            width={75}
            visible={spinnerLoading}
        />
        <button id="checkout" type="button" onClick={handleClickCheckout} disabled={hasSubscription}>Plan A{hasSubscription && ' - Disabled'}</button><br/><br/>
    </div>
</form>
</section> 
<section className={classes.billing}>
<p className={classes.planTitle}>Plan B</p>
<form>
    <div className={classes.control}>
        <p>If you already have a subscription, adding a new one will be disabled</p>
        {itIsChecking && <p>Checking if subscription exists, please wait</p>}
    </div>
    <div className={classes.actions}>
        <Loader
            type="Puff"
            color="#00BFFF"
            height={75}
            width={75}
            visible={spinnerLoading}
        />
        <button id="checkouPlanB" type="button" onClick={handleClickCheckoutPlanB} disabled={hasSubscription}>Plan B{hasSubscription && ' - Disabled'}</button>
    </div>
</form>
</section> 
        </Container>
      </React.Fragment>
    );
}

export default Checkout;
