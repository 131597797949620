import { memo, useState } from 'react';
import Snackbar from '@material-ui/core/Snackbar';
import Alert from '@material-ui/lab/Alert';
type Props = {
  message: string;
  type: number;
};

export const AlertMessage = memo((props: Props) => {
  const [open, setOpen] = useState(true);

  const handleClose = () => {
    setOpen(false);
  };

  console.log('Calling Alert FC ');

  return (
    <div>
      <Snackbar
        id="snackbar-alert"
        open={(props.message ? true : false) && open}
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
        autoHideDuration={6000}
        onClose={handleClose}
      >
        <Alert severity={props.type === 200 ? 'success' : 'error'}>{props.message}</Alert>
      </Snackbar>
    </div>
  );
});

AlertMessage.displayName = 'AlertMessage';

export default AlertMessage;
