import React, { useContext } from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';
import Layout from './components/Layout/Layout';
import UserProfile from './components/Profile/UserProfile';
import AuthPage from './pages/AuthPage';
import BillingCancelPage from './pages/BillingCancelPage';
import BillingCheckoutPage from './pages/BillingCheckoutPage';
import BillingSuccessPage from './pages/BillingSuccessPage';
import CreateUserPage from './pages/CreateUserPage';
import StickersPage from './pages/StickersPage';
import DashboardPage from './pages/DashboardPage';
import DocumentationPage from './pages/DocumentationPage';
import ForgotPasswordPage from './pages/ForgotPasswordPage';
import HomePage from './pages/HomePage';
import AuthContext from './store/auth-context';

function App(): JSX.Element {
  const authCtx = useContext(AuthContext);

  return (
    <Layout>
      <Switch>
        <Route path="/" exact>
          <HomePage />
        </Route>
        {!authCtx.isLoggedIn && (
          <Route path="/auth">
            <AuthPage />
          </Route>
        )}
        {authCtx.isLoggedIn && (
          <Route path="/profile">
            <UserProfile />
          </Route>
        )}
        {authCtx.isLoggedIn && (
          <Route path="/dashboard">
            <DashboardPage />
          </Route>
        )}
        {authCtx.isLoggedIn && (
          <Route path="/documentation">
            <DocumentationPage />
          </Route>
        )}
        {authCtx.isLoggedIn && (
          <Route path="/checkout">
            <BillingCheckoutPage />
          </Route>
        )}
          {authCtx.isLoggedIn && (
          <Route path="/stickers">
            <StickersPage />
          </Route>
        )}
        {authCtx.isLoggedIn && (
          <Route path="/checkoutSuccess">
            <BillingSuccessPage />
          </Route>
        )}
        {authCtx.isLoggedIn && (
          <Route path="/checkoutCancel">
            <BillingCancelPage />
          </Route>
        )}
        {authCtx.isLoggedIn && (
          <Route path="/createUser">
            <CreateUserPage />
          </Route>
        )}
        <Route path="/forgotPassword">
          <ForgotPasswordPage />
        </Route>
        <Route path="*">
          <Redirect to="/" />
        </Route>
      </Switch>
    </Layout>
  );
}

export default App;
