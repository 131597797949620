import React, { FC, Fragment } from 'react';
import { useState, useRef, useContext } from 'react';
import SettingsContext from '../../store/settings-context';
import {
  CssBaseline,
  FormControl,
  InputLabel,
  makeStyles,
  MenuItem,
  Select
} from '@material-ui/core';
import Typography from '@material-ui/core/Typography';
import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Container from '@material-ui/core/Container';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import AlertMessage from '../Alerts/AlertMessage';

const useStyles = makeStyles(theme => ({
  paper: {
    marginTop: theme.spacing(8),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center'
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: 'teal'
  },
  form: {
    width: '100%',
    marginTop: theme.spacing(1)
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
    backgroundColor: 'teal'
  },
  formControl: {
    margin: theme.spacing(0),
    minWidth: 120
  },
  selectEmpty: {
    marginTop: theme.spacing(2)
  }
}));

const CreateUserForm: FC = () => {
  const classes = useStyles();

  const [alertType, setAlertType] = useState<number>(200);
  const [alertMessage, setAlertMessage] = useState<string>('');

  const settingsCtx = useContext(SettingsContext);

  const emailInputRef = useRef<HTMLInputElement | null>(null);
  const customerInputRef = useRef<HTMLInputElement | null>(null);
  const roleSelectRef = useRef<HTMLSelectElement | null>(null);

  const [isLoading, setIsLoading] = useState(false);

  const submitHandler = (event: React.FormEvent) => {
    event.preventDefault();

    const enteredEmail = emailInputRef.current?.value;
    const enteredCustomer = customerInputRef.current?.value;
    const selectedRole = roleSelectRef.current?.value;

    setIsLoading(true);

    const url = process.env.REACT_APP_API_MANAGEMENT_REGISTER_USER as string;
    let registrationKey = '';

    settingsCtx.registrationKey.then((value: string) => {
      registrationKey = value;

      fetch(url, {
        method: 'POST',
        body: JSON.stringify({
          username: enteredEmail,
          password: '',
          customer: enteredCustomer,
          userRoles: [selectedRole]
        }),
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          registrationkey: registrationKey
        }
      }).then(res => {
        setIsLoading(false);
        if (res.ok) {
          setAlertMessage(`User account for ${enteredEmail} created with success.`);
          setAlertType(200);
          return res.json();
        } else {
          return res.json().then(data => {
            let errorMessage = 'Registration failed';
            if (data) {
              errorMessage = data;
            }
            setAlertMessage(errorMessage);
            setAlertType(500);
          });
        }
      });
    });
  };

  return (
    <Fragment>
      <AlertMessage message={alertMessage} type={alertType} key={alertMessage} />
      <Container component="main" maxWidth="xs">
        <CssBaseline />
        <div className={classes.paper}>
          <Avatar className={classes.avatar}>
            <LockOutlinedIcon />
          </Avatar>
          <Typography component="h1" variant="h5">
            Register User
          </Typography>
          <form className={classes.form} noValidate onSubmit={submitHandler}>
            <FormControl required className={classes.formControl} fullWidth>
              <TextField
                variant="outlined"
                required
                fullWidth
                id="email"
                label="Email Address"
                name="email"
                autoComplete="email"
                margin="normal"
                autoFocus
                inputRef={emailInputRef}
              />
            </FormControl>
            <FormControl required className={classes.formControl} fullWidth>
              <TextField
                autoComplete="customer"
                name="customer"
                variant="outlined"
                required
                fullWidth
                id="customer"
                label="Customer"
                margin="normal"
                autoFocus
                inputRef={customerInputRef}
              />
            </FormControl>
            <FormControl required className={classes.formControl} fullWidth>
              <InputLabel>Select Role</InputLabel>
              <Select name="role" required id="role" label="Role" inputRef={roleSelectRef}>
                <MenuItem value="">
                  <em>Select Role</em>
                </MenuItem>
                <MenuItem value="customer">Customer</MenuItem>
                <MenuItem value="administrator">Administrator</MenuItem>
              </Select>
            </FormControl>
            <div>
              {isLoading && <p>Creating...</p>}
              {!isLoading && (
                <Button
                  type="submit"
                  fullWidth
                  variant="contained"
                  color="primary"
                  className={classes.submit}
                >
                  Create User
                </Button>
              )}
            </div>
          </form>
        </div>
      </Container>
    </Fragment>
  );
};

export default CreateUserForm;
