import React from 'react';
import Checkout from "../components/Billing/Checkout";

const BillingCheckoutPage = ():JSX.Element => {

   return (
      <Checkout />
   );
};

export default BillingCheckoutPage;
