/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
/* eslint-disable @typescript-eslint/no-empty-function */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useState, useEffect, useCallback } from 'react';

let logoutTimer: ReturnType<typeof setTimeout>;

const AuthContext = React.createContext({
    token: '',
    isLoggedIn: false,
    role: '',
    username: '',
    userId: '',
    login: (token: any) => { },
    // eslint-disable-next-line @typescript-eslint/no-empty-function
    logout: () => { }
});

export const parseJWT = (token: any) => {
    if (!token) { return; }
    const base64Url = token.split('.')[1];
    const base64 = base64Url.replace('-', '+').replace('_', '/');
    return JSON.parse(window.atob(base64));
}

const calculateRemainingTime = (expirationTime: Date) => {
    const currentTime = new Date().getTime();
    const adjExpirationTime = new Date(expirationTime).getTime();

    const remainingDuration = adjExpirationTime - currentTime;

    return remainingDuration;
};

const retrieveStoredToken = () => {
    const storedToken = localStorage.getItem('token');
    const storedRole = localStorage.getItem('role');
    const storedUsername = localStorage.getItem('username');
    const storedUserId = localStorage.getItem('userId');
    const storedExpirationDate = localStorage.getItem('expirationTime');

    const parsedDate: Date = storedExpirationDate !== null ?  new Date(storedExpirationDate) : new Date();

    const remainingTime = calculateRemainingTime(parsedDate);

    if (remainingTime <= 60000) {
        localStorage.removeItem('token');
        localStorage.removeItem('role');
        localStorage.removeItem('username');
        localStorage.removeItem('userId');
        localStorage.removeItem('expirationTime');
        return null;
    }

    return {
        token: storedToken,
        username: storedUsername,
        userId: storedUserId,
        role: storedRole,
        duration: remainingTime
    };
};

export const AuthContextProvider = (props: any) => {
    const tokenData = retrieveStoredToken();
    let initialToken;
    let initialRole;
    let initialUsername;
    let initialUserId;

    if (tokenData) {
        initialToken = tokenData.token;
        initialRole = tokenData.role;
        initialUsername = tokenData.username;
        initialUserId = tokenData.userId;
    }
    const [token, setToken] = useState(initialToken);
    const [role, setRole] = useState(initialRole);
    const [username, setUsername] = useState(initialUsername);
    const [userId, setUserId] = useState(initialUserId);
    const userIsLoggedIn = !!token;

    const logoutHandler = useCallback(() => {
        setToken(null);
        setRole(null);
        setUsername(null);
        setUserId(null);
        localStorage.removeItem('token');
        localStorage.removeItem('role');
        localStorage.removeItem('username');
        localStorage.removeItem('userId');
        localStorage.removeItem('expirationTime');

        if (logoutTimer) {
            clearTimeout(logoutTimer);
        }
    }, []);

    const loginHandler = (token: any) => {
        setToken(token);

        const tokenData = parseJWT(token);
        const expirationTime = new Date(0);
        expirationTime.setUTCSeconds(tokenData.exp);

        setRole(tokenData.userRoles);
        setUsername(tokenData.username);
        setUserId(tokenData.userId);

        localStorage.setItem('token', token);
        localStorage.setItem('role', tokenData.userRoles);
        localStorage.setItem('username', tokenData.username);
        localStorage.setItem('userId', tokenData.userId);
        localStorage.setItem('expirationTime', expirationTime.toString());

        const remainingTime = calculateRemainingTime(expirationTime);

        logoutTimer = setTimeout(logoutHandler, remainingTime);
    };

    useEffect(() => {
        if (tokenData) {
            console.log(tokenData.duration);
            logoutTimer = setTimeout(logoutHandler, tokenData.duration);
        }
    }, [tokenData, logoutHandler]);

    const contextValue: any = {
        token: token,
        username: username,
        userId: userId,
        role: role,
        isLoggedIn: userIsLoggedIn,
        login: loginHandler,
        logout: logoutHandler
    }

    return (
                <AuthContext.Provider value={contextValue}>
                    {props.children}
                </AuthContext.Provider>
            );
};

export default AuthContext;
