import React, { FC, Fragment, useState } from 'react';
import { useContext, useRef } from 'react';

import AuthContext from '../../store/auth-context';

import {
  CssBaseline,
  FormControl,
  IconButton,
  InputAdornment,
  makeStyles
} from '@material-ui/core';
import Typography from '@material-ui/core/Typography';
import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Container from '@material-ui/core/Container';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import { Visibility, VisibilityOff } from '@material-ui/icons';

import AlertMessage from '../Alerts/AlertMessage';

const useStyles = makeStyles(theme => ({
  paper: {
    marginTop: theme.spacing(8),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center'
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: 'teal'
  },
  form: {
    width: '100%',
    marginTop: theme.spacing(1)
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
    backgroundColor: 'teal'
  },
  formControl: {
    margin: theme.spacing(0),
    minWidth: 120
  },
  selectEmpty: {
    marginTop: theme.spacing(2)
  },
  container: { margin: theme.spacing(2) }
}));

let passwordChgTries = 1;

const ProfileForm: FC = () => {
  const classes = useStyles();

  const [alertType, setAlertType] = useState<number>(200);
  const [alertMessage, setAlertMessage] = useState<string>('');

  const currentPasswordInputRef = useRef<HTMLInputElement | null>(null);
  const newPasswordInputRef = useRef<HTMLInputElement | null>(null);

  const [inputs, setInputs] = useState([
    {
      id: 'current-password',
      label: 'Current Password',
      name: 'current-password',
      value: '',
      inputRef: currentPasswordInputRef,
      error: false,
      helperText: 'Upper lower case combination and least length 6',
      getHelperText: (error: boolean) =>
        error
          ? 'Woops, not a valid password'
          : 'Upper lower case combination and least length 6',
      isValid: (value: string) => /^(?=.*?[A-Z])(?=.*?[a-z]).{6,}$/.test(value)
    },
    {
      id: 'new-password',
      label: 'New Password',
      name: 'new-password',
      value: '',
      inputRef: newPasswordInputRef,
      error: false,
      helperText: 'Upper lower case combination and at least 6 of them',
      getHelperText: (error: boolean) =>
        error
          ? 'Woops, not a valid password'
          : 'Upper lower case combination and least length 6',
      isValid: (value: string) => /^(?=.*?[A-Z])(?=.*?[a-z]).{6,}$/.test(value)
    }
  ]);

  const onChange = ({ target: { id, value } }: { target: { id: string; value: string } }) => {
    const newInputs = [...inputs];
    const index = inputs.findIndex(input => input.id === id);
    const input = inputs[index];
    const isValid = input.isValid(value);

    newInputs[index] = {
      ...input,
      value: value,
      error: !isValid,
      helperText: input.getHelperText(!isValid)
    };
    setInputs(newInputs);
  };

  const authCtx = useContext(AuthContext);

  const submitHandler = (event: React.FormEvent) => {
    event.preventDefault();

    const enteredCurrentPassword = currentPasswordInputRef.current?.value;
    const enteredNewPassword = newPasswordInputRef.current?.value;

    const url = process.env.REACT_APP_API_MANAGEMENT_CHANGE_PASSWORD_USER as string;

    fetch(url, {
      method: 'POST',
      body: JSON.stringify({
        token: authCtx.token,
        currentPassword: enteredCurrentPassword,
        newPassword: enteredNewPassword
      }),
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json'
      }
    }).then(res => {
      if (res.ok) {
        setAlertMessage('Password changed with success.');
        setAlertType(200);
      } else {
        setAlertMessage(`Password change failed. Try ${passwordChgTries.toString()}.`);
        setAlertType(500);
        passwordChgTries += 1;
      }
    });
  };

  const [showPassword, setShowPassword] = useState(false);

  const handleClick = () => {
    setShowPassword(prev => !prev);
  };

  return (
    <Fragment>
      <AlertMessage message={alertMessage} type={alertType} key={alertMessage} />
      <Container component="main" maxWidth="xs">
      <CssBaseline />
      <div className={classes.paper}>
        <Avatar className={classes.avatar}>
          <LockOutlinedIcon />
        </Avatar>
        <Typography component="h1" variant="h5">
          Change Password
        </Typography>
        <Typography component="h3" variant="h5">
          {authCtx.username}
        </Typography>
        <form className={classes.form} onSubmit={submitHandler}>
          {inputs.map(input => (
            <FormControl required className={classes.formControl} fullWidth key={input.id}>
              <TextField
                id={input.id}
                label={input.label}
                helperText={input.helperText}
                value={input.value}
                margin="normal"
                type={showPassword ? 'text' : 'password'}
                fullWidth
                onChange={onChange}
                error={input.error}
                inputRef={input.inputRef}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton onClick={handleClick}>
                        {showPassword ? <Visibility /> : <VisibilityOff />}
                      </IconButton>
                    </InputAdornment>
                  )
                }}
              />
            </FormControl>
          ))}
          <Button
            type="submit"
            fullWidth
            variant="contained"
            color="primary"
            className={classes.submit}
          >
            Change Password
          </Button>
        </form>
      </div>
    </Container>
    </Fragment>
  );
};

export default ProfileForm;
