import { useEffect, useContext, useState, Fragment, FC } from 'react';
import AuthContext from '../../store/auth-context';
import OrdersList from '../Orders/OrdersList';
import StickersList from '../Stickers/StickersList';
import classes from './Dashboard.module.css';
import AlertMessage from '../Alerts/AlertMessage';
import InvoicesList from '../Invoices/InvoicesList';

const DashboardCustomer: FC = () => {
  const [orders, setOrders] = useState([]);
  const [stickers, setStickers] = useState([]);
  const [AllStickersByUser, setGetStickers] = useState([]);
  const [invoices, setInvoices] = useState([]);
  const [isLoadingOrders, setIsLoadingOrders] = useState(false);
  const [isLoadingStickers, setIsLoadingStickers] = useState(false);
  const [isLoadingStickersByUser, setIsLoadingStickersByUser] = useState(false);
  const [isLoadingInvoices, setIsLoadingInvoices] = useState(false);
  const [nextPayDate, setNextPayDate] = useState(null);

  const [alertType, setAlertType] = useState<number>(200);
  const [alertMessage, setAlertMessage] = useState<string>('');

  const authCtx = useContext(AuthContext);

  const urlOrders = process.env.REACT_APP_API_GATEWAY + `getOrdersByUser?userId=${authCtx.userId}`;
  const urlInvoices = process.env.REACT_APP_API_GATEWAY + `getInvoicesByUser?userId=${authCtx.userId}`;
  const urlStickers = process.env.REACT_APP_API_GATEWAY + 'getAllStickers';
  const urlStickersByUser = process.env.REACT_APP_API_GATEWAY + `getAllStickersByUser?userId=${authCtx.userId}`;
  const urlCustomerSubscription =
    process.env.REACT_APP_API_GATEWAY +
    `getCustomerSubscriptionByCustomer?userId=${authCtx.userId}`;

  const authHeaderValue = 'Bearer ' + authCtx.token;

  const getApiKeyHandler = (event: React.FormEvent) => {
    event.preventDefault();

    const url = process.env.REACT_APP_API_MANAGEMENT_GET_API_KEY as string;
    fetch(url, {
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: authHeaderValue
      }
    })
      .then(async res => {
        if (res.ok) {
          return res.json();
        } else {
          const errorMessage = 'Get API Key failed';
          throw new Error(errorMessage);
        }
      })
      .then(() => {
        setAlertMessage('API Key sent with success via your email.');
        setAlertType(200);
      })
      .catch(err => {
        setAlertMessage(err.message);
        setAlertType(500);
      });
  };

  useEffect(() => {
    async function getCustomerSubscriptionNextChargeDate() {
      const response = await fetch(urlCustomerSubscription, {
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          Authorization: authHeaderValue
        }
      });
      await response.json().then(data => {
        if (data.stripeSubscriptionId) {
          const dateOfCharge = data.currentPeriodEnd;

          setNextPayDate(dateOfCharge);
        }
      });
    }
    getCustomerSubscriptionNextChargeDate();
  }, [authHeaderValue, urlCustomerSubscription]);

  useEffect(() => {
    setIsLoadingOrders(true);

    async function getAllOrders() {
      const response = await fetch(urlOrders, {
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          Authorization: authHeaderValue
        }
      });
      await response
        .json()
        .then(data => {
          setOrders(data);
          setIsLoadingOrders(false);
        })
        .catch(() => {
          setIsLoadingOrders(false);
        });
    }
    getAllOrders();
  }, [authCtx.token, authHeaderValue, urlOrders]);

  useEffect(() => {
    setIsLoadingInvoices(true);

    async function getAllInvoices() {
      const response = await fetch(urlInvoices, {
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          Authorization: authHeaderValue
        }
      });
      await response
        .json()
        .then(data => {
          setInvoices(data);
          setIsLoadingInvoices(false);
        })
        .catch(() => {
          setIsLoadingInvoices(false);
        });
    }
    getAllInvoices();
  }, [authCtx.token, authHeaderValue, urlInvoices]);

  useEffect(() => {
    setIsLoadingStickers(true);
    async function getAllStickers() {
      const response = await fetch(urlStickers, {
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          Authorization: authHeaderValue
        }
      });
      const data = await response.json();
      setStickers(data);
      setIsLoadingStickers(false);
    }
    getAllStickers();
  }, [authHeaderValue, urlStickers]);

  useEffect(() => {
    setIsLoadingStickersByUser(true);
    async function getAllStickersByUser() {
      const response = await fetch(urlStickersByUser, {
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          Authorization: authHeaderValue
        }
      });
      const data = await response.json();
      setGetStickers(data);
      setIsLoadingStickersByUser(false);
    }
    getAllStickersByUser();
  }, [authHeaderValue, urlStickersByUser]);

  return (
    <Fragment>
      <AlertMessage message={alertMessage} type={alertType} />
      <div className={classes.dashboard}>
        <section>
          <p>Hello {authCtx.username}</p>
          <form onSubmit={getApiKeyHandler}>
            <div className={classes.actions}>
              <button type="submit">Get email with my API KEY</button>
            </div>
          </form>
        </section>
        <section>
          <h1>My Stickers Orders</h1>
          <OrdersList orders={orders} isLoading={isLoadingOrders} nextPayDate={nextPayDate} />
        </section>
        <section>
          <h1>My Invoices</h1>
          <InvoicesList invoices={invoices} isLoading={isLoadingInvoices}/>
        </section>
        <section>
          <h1>My Stickers</h1>
          <StickersList stickers={AllStickersByUser} isLoading={isLoadingStickersByUser} />
        </section>
        <section>
          <h1>Available Stickers I can buy!</h1>
          <StickersList stickers={stickers} isLoading={isLoadingStickers} />
        </section>
      </div>
    </Fragment>
  );
};

export default DashboardCustomer;
